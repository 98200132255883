import React, { useState } from 'react';
import Button from '../Button';
import SettingPopup from './SettingPopup';
import WalletPopup from '../WalletPopup';

const ConnectWallet = (props) => {
    const {
        userAccount,
    } = props;
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [openSettingPopup, setOpenSettingPopup] = useState(false);

    return (
        <>
            {userAccount ? (
                <div className='relative'>
                    <Button
                        className='bg-secondary rounded-full w-full h-50px'
                        onClick={() => setOpenSettingPopup(true)}
                    >
                        <span className='text-green-primary font-semibold'>
                            {`Connected: ${userAccount.slice(0, 6)}...${userAccount.slice(userAccount.length - 4, userAccount.length)}`} &nbsp;
                        </span>
                        {/* <img src={ChevronIcon} alt="" className='w-6 h-6 ml-2'/> */}
                    </Button>
                    {openSettingPopup && (
                        <SettingPopup
                            setOpenPopup={setOpenSettingPopup}
                        />
                    )}
                </div>
            ) : (
                <Button
                    type='contained'
                    className='bg-green-primary rounded-full w-full h-50px'
                    onClick={() => setOpenWalletPopup(true)}
                >
                    Connect wallet
                </Button>
            )}
            {openWalletPopup && (
                <WalletPopup
                    setOpenPopup={setOpenWalletPopup}
                />
            )}
        </>
    )
}

export default ConnectWallet;