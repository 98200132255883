import React, { useEffect, useState } from 'react';
import ReactSlider from "react-slider";

const SliderBlock = ({ bnbAmount, setBnbAmount, maxBnb, minBnb }) => {
  const [bnb, setBnb] = useState(0);
  useEffect(() => {
    setBnb(bnbAmount !== '' ? bnbAmount : 0);
  }, [bnbAmount])

  const handleChange = (value) => {
    setBnbAmount(value / 100);
  }

  return (
    <div className='bg-secondary rounded-default px-4 md:px-9 py-5'>
      <p className='text-15 text-gray-light mb-6'>
        Amount: <span className='text-white'>{bnb} BNB</span>
      </p>
      <div>
        <div className='w-full mb-3'>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            renderThumb={(props, state) => <div {...props}>{state.valueNow / 100}</div>}
            min={minBnb * 100}
            max={maxBnb * 100}
            value={bnb * 100}
            onChange={handleChange}
          />
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-15'>{minBnb} BNB</p>
          <p className='text-15'>{maxBnb} BNB</p>
        </div>
      </div>
    </div>
  )
}

export default SliderBlock;