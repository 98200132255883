import React from 'react';
import Button from './Button';
import BnbIcon from '../assets/images/bnb-icon.png';

const TokenInputBox = ({bnbAmount, setBnbAmount, maxBnb}) => {
  return (
    <div className='border border-green-primary rounded-full w-full h-50px px-14px flex items-center'>
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center'>
          <img src={BnbIcon} alt="" className='w-30px h-30px' />
          <span className='text-15 text-gray-light pl-3'>BNB</span>
        </div>
        <div className='flex items-center'>
          <input
            type='number'
            pattern="[0-9]*"
            inputMode="numeric"
            className='w-44 border-none outline-none px-3 bg-primary text-right'
            placeholder='0.0'
            value={bnbAmount}
            onChange={(e) => {
              if (e.target.value) {
                setBnbAmount(Math.abs(e.target.value));
              } else {
                setBnbAmount('');
              }
            }}
          />
          <Button
            className='bg-green-overlay rounded-full w-14 h-7'
            onClick={() => setBnbAmount(maxBnb)}
          >
            <span className='text-green-primary text-12'>MAX</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TokenInputBox;