import React from 'react';
import LogoutIcon from '../../assets/images/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/wallet.redux';
import { getDefaultWallet } from '../../helper/connect-wallet';


export default function SettingPopup(props) {
    const { setOpenPopup } = props;
    const dispatch = useDispatch();
    const wallet = useSelector(state => state.wallet.wallet);

    const disconnect = async () => {
        const walletType = localStorage.getItem('wallet_type');
        const netId = localStorage.getItem('chainID');

        if (walletType === 'wallet-connect') {
            await wallet.wcProvider.disconnect();
            localStorage.clear();
            localStorage.setItem('chainID', netId);
            window.location.reload();
            setOpenPopup(false);
        } else if (walletType === 'metamask') {
            localStorage.clear();
            localStorage.setItem('chainID', netId);
            const defaultWallet = getDefaultWallet();
            dispatch(actions.updateWalletAction(defaultWallet));
            setOpenPopup(false);
        }
    }

    return (
        <>
            <div className='fixed w-full h-screen inset-0 z-10' onClick={() => setOpenPopup(false)} />
            <div
                className='absolute right-0 bg-secondary shadow cursor-pointer px-6 py-2.5 z-50 rounded-lg flex items-center'
                style={{top: 53}}
                onClick={() => disconnect()}
            >
                <span>Disconnect</span>
                <img src={LogoutIcon} alt="" className='w-5 h-5 ml-3'/>
            </div>
        </>
    )
}