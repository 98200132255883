import React from 'react';

const AmountRaisedBlock = ({totalDeposit, softCap, hardCap}) => {
  const rate = (totalDeposit / hardCap) * 100;
  return (
    <div className='bg-secondary rounded-default px-9 py-7'>
      <div className='flex justify-between items-center'>
        <p className='text-15 text-gray-light mb-4'>
          Amount Raised: <span className='text-white'>{totalDeposit.toFixed(2)} BNB</span>
        </p>
        <p className='text-15'>{rate.toFixed(2)} %</p>
      </div>
      <div className='relative w-full h-15px bg-gray-dark rounded-full'>
        <div
          className='absolute top-0 left-0 h-15px bg-green-primary rounded-full'
          style={{width: `${rate}%`, zIndex: 0}}
        />
      </div>
      <div className='relative w-full'>
        <div className='flex justify-end'>
          <div className='border-r-2 border-gray-dark pr-2 pt-3.5 mt-1.5'>
            <p className='text-15 text-white text-right'>{hardCap} BNB</p>
            <p className='text-15 text-white opacity-20 text-right'>Hard Cap</p>
          </div>
        </div>
        <div className='absolute top-0 left-0 flex justify-end' style={{width: `${(softCap / hardCap) * 100}%`}}>
          <div className='border-r-2 border-gray-dark pr-2 pt-3.5 mt-1.5'>
            <p className='text-15 text-white text-right'>{softCap} BNB</p>
            <p className='text-15 text-white opacity-20 text-right'>Soft Cap</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AmountRaisedBlock;