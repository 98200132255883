import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import Button from './Button';

const TextButtonBlock = ({text, btnLabel, handleClick, loading}) => {
  return (
    <div className='bg-secondary rounded-default px-4 md:px-9 py-6 w-full h-full flex flex-col items-center justify-between'>
      <div className='pb-4'>
        <p className='text-12'>{text}</p>
      </div>
      <div>
        <Button
          className='bg-green-primary rounded-full h-10 w-52 py-0'
          onClick={handleClick ? handleClick : () => {}}
        >
          {loading ? (
            <div className='mt-2'>
              <PulseLoader size={12} margin={6} color="#ffffff"/>
            </div>
          ) : (
            <span className='text-14'>{btnLabel}</span>
          )}
        </Button>
      </div>
    </div>
  )
}

export default TextButtonBlock;