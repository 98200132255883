import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PulseLoader from "react-spinners/PulseLoader";
import Header from '../components/Header';
import TokenInputBox from '../components/TokenInputBox';
import ConnectWallet from '../components/ConnectWallet/index';
import AmountBlock from '../components/AmountBlock';
import AmountRaisedBlock from '../components/AmountRaisedBlock';
import TimerBlock from '../components/TimerBlock';
import SliderBlock from '../components/SliderBlock';
import SelectCountry from '../components/SelectCountry';
import Button from '../components/Button';
import TextButtonBlock from '../components/TextButtonBlock';
import TermsPopup from '../components/TermsPopup';
import CheckIcon from '../assets/images/check-icon.png';
import CloseIcon from '../assets/images/close-icon.svg';
import { actions } from '../redux/wallet.redux';
import { getWallet } from '../helper/connect-wallet';
import {
  presaleContract,
  decimals
} from '../abis/constants';
// import { getBNBPrice } from '../services/contract';
import {
  formatDate,
  makeBNumber,
  parseBNumber
} from '../services/utils';
import MailchimpPopup from '../components/MailchimpPopup';
import SettingPopup from '../components/ConnectWallet/SettingPopup';

const Home = () => {
  const dispatch = useDispatch();
  const refreshCnt = useSelector(state => state.common.refreshCount);
  // const wallet = useSelector(state => state.wallet.wallet);
  const [userAccount, setUserAccount] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [bnbAmount, setBnbAmount] = useState('');
  const [psContract, setPsContract] = useState(null);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [softcapBnb, setSoftcapBnb] = useState(0);
  const [hardcapBnb, setHardcapBnb] = useState(0);
  const [minBnb, setMinBnb] = useState(0);
  const [maxBnb, setMaxBnb] = useState(0);
  const [rateBnb, setRateBnb] = useState(0);
  // const [bnbPrice, setBnbPrice] = useState(0);
  const [totalPurchased, setTotalPurchased] = useState(0);
  const [withdrawn, setWithdrawn] = useState(false);
  // const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const [timerStatus, setTimerStatus] = useState(0); // 0 < start < 1 < end < 2 < release < 3
  const [limitTime, setLimitTime] = useState('');
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [status, setStatus] = useState(0);
  const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const [openMailchimpPopup, setOpenMailchimpPopup] = useState(false);
  const [openSettingPopup, setOpenSettingPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  /**
   *  0:  not-connect
          connect
      1:	not-whitelisted
      2:	whitelisted sale pending
      3:	whitelisted contributable
      4:	whitelisted sale ends withdraw pending
      5:	whitelisted sale ends withdrawalble
   */

  // Timer
  let tmr = useRef();
  let cnt = useRef(0);
  let duration = useRef(-1000);
  const [remainDay, setRemainDay] = useState('0');
  const [remainHour, setRemainHour] = useState('00');
  const [remainMinutes, setRemainMinutes] = useState('00');
  const [remainSecond, setRemainSecond] = useState('00');

  useEffect(() => {
    // getTimes();
    // Time downCounter
    if (!tmr.current) {
      tmr.current = setInterval(async () => {
        if (duration.current <= -1000) {
          duration.current = -1000;
          setRemainDay('0');
          setRemainHour('00');
          setRemainMinutes('00');
          setRemainSecond('00');
        } else {
          const duration_in_sec = Math.round(duration.current / 1000);
          const day = Math.floor(duration_in_sec / 86400);
          const hor = Math.floor((duration_in_sec % 86400) / 3600);
          const min = Math.floor((duration_in_sec % 3600) / 60);
          const sec = duration_in_sec % 60;
          setRemainDay(day);
          setRemainHour(('00' + hor).slice(-2));
          setRemainMinutes(('00' + min).slice(-2));
          setRemainSecond(('00' + sec).slice(-2));
          duration.current -= 1000;
        }
      }, 1000)
    }

    return () => {
      clearInterval(tmr.current);
    }
  }, [])

  const initProcess = useCallback(async (wallet) => {
    try {
      setLoading(true);
      const { web3, address } = await getWallet();
      setUserAccount(address);

      if (!web3) return;

      // const bnb_price = await getBNBPrice();
      // setBnbPrice(bnb_price);

      const presale_contract = new web3.eth.Contract(presaleContract.abi, presaleContract.address);
      setPsContract(presale_contract);

      const softcap = await presale_contract.methods._SOFTCAP().call();
      const softcap_bnb = parseBNumber(softcap, decimals)
      setSoftcapBnb(softcap_bnb);

      const hardcap = await presale_contract.methods._HARDCAP().call();
      const hardcap_bnb = parseBNumber(hardcap, decimals);
      setHardcapBnb(hardcap_bnb);

      const total_deposit = await presale_contract.methods._TOTAL_DEPOSIT_BNB().call();
      const deposit = parseBNumber(total_deposit, decimals);
      setTotalDeposit(deposit);

      const min_bnb = await presale_contract.methods._MIN_BNB_CONTRIBUTION().call();
      setMinBnb(parseBNumber(min_bnb, decimals));

      const max_bnb = await presale_contract.methods._MAX_BNB_CONTRIBUTION().call();
      setMaxBnb(parseBNumber(max_bnb, decimals));

      const rate_bnb = await presale_contract.methods._RATE().call();
      setRateBnb(rate_bnb);

      const start_time = await presale_contract.methods._START_TIME().call();
      const end_time = await presale_contract.methods._END_TIME().call();
      const release_time = await presale_contract.methods._RELEASE_TOKEN_TIME().call();
      // 1646834914291
      // const start_time = 1647504914;
      // const end_time = 1647824914;
      // const release_time = 1656824974;
      let timer_status = 0;

      if (start_time * 1000 >= new Date().getTime()) {
        duration.current = start_time * 1000 - new Date().getTime();
        setLimitTime(formatDate(start_time * 1000));
        timer_status = 0;
      } else if (end_time * 1000 >= new Date().getTime()) {
        duration.current = end_time * 1000 - new Date().getTime();
        setLimitTime(formatDate(end_time * 1000));
        timer_status = 1;
      } else if (release_time * 1000 >= new Date().getTime()) {
        duration.current = release_time * 1000 - new Date().getTime();
        setLimitTime(formatDate(release_time * 1000));
        timer_status = 2;
      } else {
        duration.current = -1000;
        setLimitTime(formatDate(release_time * 1000));
        timer_status = 3;
      }

      setTimerStatus(timer_status);

      if (!address) {
        console.log('--------------status: 0-------------')
        setStatus(0);
        cnt.current ++;
        setLoading(false);
        return;
      };

      const whitelisted = await presale_contract.methods.isPresaleWhitelist(address).call();
      // const whitelisted = true;

      if (!whitelisted) {
        console.log('--------------status: 1-------------')
        setStatus(1);
      } else if (timer_status === 0) {
        console.log('--------------status: 2-------------')
        setStatus(2);
      } else if (timer_status === 1) {
        console.log('--------------status: 3-------------')
        setStatus(3);
      } else if (timer_status === 2) {
        console.log('--------------status: 4-------------')
        setStatus(4);
      } else if (timer_status === 3) {
        console.log('--------------status: 5-------------')
        setStatus(5);
      }

      const total_purchased = await presale_contract.methods.totalPurchased(address).call();
      setTotalPurchased(parseBNumber(total_purchased, decimals));

      const withdrawStatus = await presale_contract.methods.withdrawn(address).call();
      setWithdrawn(withdrawStatus);

      const balance = await web3.eth.getBalance(address);
      setUserBalance(parseBNumber(balance, decimals));

      cnt.current ++;
      setLoading(false);

    } catch (err) {
      console.log(err);
      setLoading(false);
    }

  }, [])

  // useEffect(() => {
  //   const connectBlockchain = async () => {
  //     const newWallet = await getWallet();
  //     dispatch(actions.updateWalletAction(newWallet));
  //   }
  //   connectBlockchain();
  // }, [dispatch])

  useEffect(() => {
    initProcess()
  }, [initProcess, refreshCnt]);

  useEffect(() => {
    const interval = setInterval(() => {
        initProcess();
    }, 10000);

    return () => clearInterval(interval);
  }, [initProcess])

  const contribute = async () => {
    if (!agreed) {
      toast.error('You have to agree the Terms & Conditions', { autoClose: 3000, theme: 'colored' });
      return;
    }
    try {
      if (userAccount) {
        if (bnbAmount < minBnb || bnbAmount > maxBnb) {
          toast.error(`Invalid bnb value. min value is ${minBnb} and max value is ${maxBnb}.`, { autoClose: 5000, theme: 'colored' });
        } else if (userBalance < minBnb) {
          toast.error(`Your balance is not enough.`, { autoClose: 5000, theme: 'colored' });
        } else if (totalPurchased + bnbAmount > maxBnb) {
          toast.error(`You can't contribute anymore.`, { autoClose: 5000, theme: 'colored' });
        } else {
          setLoadingDeposit(true);
          const stakeAmount = makeBNumber(bnbAmount, decimals);
          await psContract?.methods?.deposite().send({
            from: userAccount,
            value: stakeAmount
          });
          // await psContract?.methods?.deposite(bnbAmount).send({from: userAccount});

          setLoadingDeposit(false);
          dispatch(actions.changeRefresh());
        }
      } else {
        toast.error('Please connect wallet', { autoClose: 3000, theme: 'colored' });
      }

    } catch (err) {
      setLoadingDeposit(false);
      console.log(err);
    }
  }

  const withdraw = async () => {
    if (timerStatus < 3) {
      toast.error(`You can withdraw after ${limitTime}`, { autoClose: 3000, theme: 'colored' });
      return;
    }
    if (userAccount) {
      try {
        setLoadingWithdraw(true);
        await psContract?.methods?.withdraw().send({ from: userAccount });

        setLoadingWithdraw(false);
        setWithdrawn(true);
        dispatch(actions.changeRefresh());

      } catch (err) {
        setLoadingWithdraw(false);
        console.log(err);
      }
    } else {
      toast.error('Please connect wallet', { autoClose: 3000, theme: 'colored' });
    }
  }

  return (
    <>
      <Header />
      <div className='py-10 md:py-2'>
        <div className='w-full max-w-screen-md mx-auto px-4'>
          {(loading && cnt.current === 0) ? (
            <div className='flex items-center justify-center opacity-30'>
              <div className='mt-2 mr-3'>
                <PulseLoader size={8} margin={6} color="#ffffff"/>
              </div>
              <span>Reading data</span>
            </div>
          ) : (<div className='h-9'></div>)}
          <div className='grid grid-cols-1 md:grid-cols-10 gap-5 mt-2'>
            <div className='col-span-1 md:col-span-6'>
              {status < 4 ? (
                <TokenInputBox bnbAmount={bnbAmount} setBnbAmount={setBnbAmount} maxBnb={maxBnb} />
              ) : (
                <span className='text-15'>Betero presale has ended. Thanks for participating!</span>
              )}
            </div>

            <div className='col-span-1 md:col-span-4 hidden md:block'>
              {(status === 0 || status === 4 || status === 5) && (
                <ConnectWallet userAccount={userAccount} />
              )}
              {status === 1 && (
                <div className='relative'>
                  <NotWhitelistedButton handleClick={() => setOpenSettingPopup(true)} />
                  {openSettingPopup && (
                    <SettingPopup
                        setOpenPopup={setOpenSettingPopup}
                    />
                  )}
                </div>
              )}
              {status === 2 && <WhitelistedButton />}
              {status === 3 && <ContributeButton handleClick={contribute} loading={loadingDeposit} />}
            </div>

            <div className={`col-span-1 ${status < 4 ? 'md:col-span-6' : 'md:col-span-5'}`}>
              {status < 4 && (
                <SliderBlock bnbAmount={bnbAmount} setBnbAmount={setBnbAmount} maxBnb={maxBnb} minBnb={minBnb}/>
              )}
              {(status === 4 || status === 5) && (
                <AmountBlock label={'You Own'} amount={totalPurchased * rateBnb} />
              )}
            </div>

            <div className='col-span-1 md:col-span-4 block md:hidden'>
              {(status === 0 || status === 4 || status === 5) && (
                <ConnectWallet userAccount={userAccount} />
              )}
              {status === 1 && (
                <div className='relative'>
                  <NotWhitelistedButton handleClick={() => setOpenSettingPopup(true)} />
                  {openSettingPopup && (
                    <SettingPopup
                        setOpenPopup={setOpenSettingPopup}
                    />
                  )}
                </div>
              )}
              {status === 2 && <WhitelistedButton />}
              {status === 3 && <ContributeButton handleClick={contribute} loading={loadingDeposit} />}
            </div>

            <div className={`col-span-1 ${status < 4 ? 'md:col-span-4' : 'md:col-span-5'}`}>
              <div className='flex flex-col justify-between md:items-center h-full w-full'>
                {(status === 2 || status === 3) && totalPurchased === 0 && (
                  <div className='w-full md:w-52'>
                    <SelectCountry />
                  </div>
                )}
                {(status === 0 || status === 2 || status ===3) && totalPurchased === 0 && (
                  <div onClick={() => setOpenTermsPopup(true)}>
                    <TermsConditionsBlock agreed={agreed} />
                  </div>
                )}
                {status === 1 && (
                  <JoinWaitingButton setOpenPopup={setOpenMailchimpPopup} />
                )}
                {status < 4 && (
                  <AmountBlock
                    label={'Amount Recieved'}
                    amount={bnbAmount * rateBnb}
                    totalPurchased={status === 3 ? totalPurchased : null}
                  />
                )}
                {status === 4 && (
                  <TextButtonBlock text='You can withdraw your tokens once all sale rounds are completed and liquidity has been added. Approximately 2nd of April.' btnLabel='Withdraw Soon' />
                )}
                {status === 5 && (
                  <TextButtonBlock
                    text='Withdraw your Betero tokens now.'
                    btnLabel={withdrawn ? 'Already withdrawn' : 'Withdraw'}
                    handleClick={withdrawn ? null : withdraw}
                    loading={loadingWithdraw}
                  />
                )}
              </div>
            </div>

            <div className='col-span-1 md:col-span-10'>
              <AmountRaisedBlock totalDeposit={totalDeposit} hardCap={hardcapBnb} softCap={softcapBnb}/>
            </div>

            <div className='col-span-1 md:col-span-10'>
              <TimerBlock timerStatus={timerStatus} remainDay={remainDay} remainHour={remainHour} remainMinutes={remainMinutes} remainSecond={remainSecond} />
            </div>

          </div>
        </div>
      </div>
      {openTermsPopup && (
          <TermsPopup
              setOpenPopup={setOpenTermsPopup}
              setAgreed={setAgreed}
          />
      )}
      {openMailchimpPopup && (
        <MailchimpPopup
          setOpenPopup={setOpenMailchimpPopup}
        />
      )}
    </>
  )
}

const TermsConditionsBlock = ({agreed}) => (
  <div className='flex items-center py-2.5'>
    <div className={`${agreed ? 'bg-green-primary' : 'border border-green-primary'} rounded-full w-4 h-4 mr-3`} />
    <span className='text-green-primary text-10'>I have read the Terms &amp; Conditions</span>
  </div>
)

const WhitelistedButton = () => (
  <Button className='bg-green-primary rounded-full h-50px w-full'>
    <div>
      <div className='flex items-center mb-1'>
        <span className='leading-none'>You are whitelisted</span>
        <img src={CheckIcon} alt="" className='w-14px h-3 ml-3 mb-1' />
      </div>
      <div className='text-center uppercase text-8 text-primary leading-none'>Sale starting soon</div>

    </div>
  </Button>
)

const NotWhitelistedButton = ({handleClick}) => (
  <Button className='bg-green-overlay rounded-full h-50px w-full' onClick={handleClick}>
    <div>
      <div className='flex items-center mb-1'>
        <span className='leading-none'>NOT whitelisted</span>
        <img src={CloseIcon} alt="" className='w-3 h-3 ml-3 mb-1' />
      </div>
      <div className='text-center uppercase text-8 leading-none'>Try connecting a different wallet</div>
    </div>
  </Button>
)

const ContributeButton = ({handleClick, loading}) => (
  <Button
    className='bg-green-primary rounded-full h-50px w-full'
    onClick={handleClick}
  >
    {loading ? (
      <div className='mt-2'>
        <PulseLoader size={12} margin={6} color="#ffffff"/>
      </div>
    ) : (
      <span>Contribute</span>
    )}
  </Button>
)

const JoinWaitingButton = ({setOpenPopup}) => (
  <div className='w-full md:w-56 mb-6'>
    <Button className='bg-green-primary rounded-full h-10 w-full' onClick={() => setOpenPopup(true)}>
      <span className='text-14'>Join the waiting list here</span>
    </Button>
  </div>
)

export default Home;
