import React from 'react';
import BeteroIcon from '../assets/images/betero-icon.png';
import BnbIcon from '../assets/images/bnb-icon.png';

const AmountBlock = ({label, amount, totalPurchased}) => {
  return (
    <div className='bg-secondary rounded-default px-4 md:px-9 py-14px w-full h-full'>
      {!!totalPurchased && (
        <>
          <p className='text-15 text-gray-light mb-3'>{'Contributed'}: </p>
          <div className='flex items-center mb-2'>
            <div className='bg-green-overlay w-38px h-38px rounded-sm p-1.5'>
              <img src={BnbIcon} alt="" className='w-full h-full'/>
            </div>
            <span className='text-19 font-semibold ml-2'>{totalPurchased?.toFixed(2)} BNB</span>
          </div>
        </>
      )}
      <p className='text-15 text-gray-light mb-3'>{label}:</p>
      <div className='flex items-center'>
        <div className='bg-green-overlay w-38px h-38px rounded-sm p-1.5'>
          <img src={BeteroIcon} alt="" className='w-full h-full'/>
        </div>
        <span className='text-19 font-semibold ml-2'>{amount?.toFixed(2)} BTE</span>
      </div>
    </div>
  )
}

export default AmountBlock;