// wallet-connect.ts
import Web3 from "web3";

// This is the SDK provided by Wallet Connect
import WalletConnectProvider from "@walletconnect/web3-provider";
// import { RPC } from "../abis/constants";
import { getDefaultWallet } from "../helper/connect-wallet";
import { DEFAULT_NET } from "../abis/constants";
// import * as utils from "../services/utils";

// Main login flow for Crypto.com DeFi Wallet with Wallet Extension
// The connector must be activated, then it exposes a provider
// that is used by the ethers Web3Provider constructor.
export const connect = async () => {
  const defaultWallet = getDefaultWallet();

  try {
    // const net_id = localStorage.getItem('chainID');
    // const netId = net_id ? parseInt(net_id) : DEFAULT_NET;

    const provider = new WalletConnectProvider({
      rpc: {
        56: 'https://bsc-dataseed.binance.org/',
        97: 'https://data-seed-prebsc-1-s1.binance.org',
        25: 'https://evm-cronos.crypto.org',
        250: 'https://rpc.ftm.tools/',
      },
      chainId: DEFAULT_NET,
    });
    await provider.enable();
    const web3 = new Web3(provider);
    localStorage.setItem('wallet_type', 'wallet-connect');
    
    if (!(parseInt(provider.chainId) === DEFAULT_NET)) {
      window.alert(
        "Switch your Wallet to blockchain network " + DEFAULT_NET
      );
    }
    // Subscribe to events that reload the app

    provider.on("chainChanged", (chainId) => {
      window.location.reload();
    });
    provider.on("accountsChanged", () => {
      window.location.reload();
    });

    return {
      ...defaultWallet,
      walletProviderName: "wallet-connect",
      address: (await web3.eth.getAccounts())[0],
      wcProvider: provider,
      web3: web3,
      connected: true,
      networkId: DEFAULT_NET,
    };
  } catch (e) {
    console.log(e)
    return defaultWallet;
  }
};
