// wallet-metamask.ts

// Injected wallet
// Works with MetaMask in browser or in in-app browser
import Web3 from "web3";
import { DEFAULT_NET, NETWORK_INFO } from "../abis/constants";
import { getDefaultWallet } from "../helper/connect-wallet";
// import * as utils from "../services/utils";

// One feature of MetaMask is that the Dapp developer
// can programmatically
// change the network that the browser
// extension is connected to.
// This feature is implemented below,
// to automatically set - up Cronos

export const switchNetwork = async (netId) => {
  /* eslint-disable */
  if (window.ethereum) {
      const tx = await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: NETWORK_INFO[netId]
      }).catch();

      if (tx) {
          console.log(tx)
      }
  }
}

// Main login flow for injected wallet like MetaMask
export const connect = async () => {
  const defaultWallet = getDefaultWallet();
  // const net_id = localStorage.getItem('chainID');
  // const netId = net_id ? parseInt(net_id) : DEFAULT_NET;

  try {
    let chainId = await window.ethereum.request({ method: "eth_chainId" });
    const networkId = Number(chainId);

    if (!(networkId === DEFAULT_NET)) {
      await switchNetwork(DEFAULT_NET);
    }
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const web3 = new Web3(window.ethereum);
    localStorage.setItem('wallet_type', 'metamask');

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });
    window.ethereum.on("accountsChanged", () => {
      window.location.reload();
    });
    // window.ethereum.on("disconnect", utils.reloadApp);

    return {
      ...defaultWallet,
      walletProviderName: "metamask",
      address: accounts[0],
      connected: true,
      web3: web3,
      networkId: networkId,
    };
  } catch (e) {
    console.log(e);
    return defaultWallet;
  }
};
