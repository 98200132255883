import React from 'react';
import { Link } from 'react-router-dom';

const Button = (props) => {
    const {className, onClick, url, children} = props;
    const isExternal = url
        ? url.indexOf('://') > 0 || url.indexOf('//') === 0
        : false;
    const CLASSES = `px-6 rounded-sm cursor-pointer flex justify-center items-center hover:opacity-80 text-15 text-white ${className}`;
    const NON_CUSOR_CLASSES = `px-6 rounded-sm flex justify-center items-center text-15 text-white ${className}`;

    if (isExternal) {
        return (
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={CLASSES}
            >
                {children}
            </a>
        )
    }
    if (onClick) {
        return (
            <div
                className={CLASSES}
                onClick={onClick}
            >
                {children}
            </div>
        )
    }
    if (url) {
        return (
            <Link
                to={url}
                className={CLASSES}
            >
                {children}
            </Link>
        )
    }

    return (
        <div className={NON_CUSOR_CLASSES}>
            {children}
        </div>
    )
}

export default Button;