import React, { useState } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CloseIcon from '../assets/images/close-icon.svg';
import { MAILCHIMP_URL } from '../services/config';

const MailchimpPopup = (props) => {
  const { setOpenPopup } = props;
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = (e, subscribe) => {
    console.log('email: ', email)
    console.log('address: ', address)
    e.preventDefault();
    address &&
    email &&
    email.indexOf("@") > -1 &&
    subscribe({MERGE0: email, MERGE1: address});
  }

  return (
    <div className='popup-container'>
      <div
        className='w-full mx-auto overflow-auto bg-primary'
        style={{ maxWidth: 470, borderRadius: 30 }}
      >
        <div className='flex items-center justify-between px-8 py-6 border-b border-green-primary'>
          <h4 className='text-19 font-bold'>Join the Betero waiting list</h4>
          <div className='cursor-pointer'>
            <img src={CloseIcon} alt="" className='w-4 h-4' onClick={() => setOpenPopup(false)} />
          </div>
        </div>
        <div className='px-8 py-6'>
          <p className='text-13 mb-8'>Enter your wallet and email address below. We will notify you once there is more allocation becoming available. We will then put your address on the whitelist automatically.</p>
          <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <>
                <form onSubmit={(e) => handleSubmit(e, subscribe)}>
                  <div className='email-input flex-center'>
                    <input
                      type="text"
                      placeholder='Wallet Address'
                      className='border-b border-green-primary bg-primary text-14 outline-none px-2 py-1 w-full mb-6'
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder='Email Address'
                      className='border-b border-green-primary bg-primary text-14 outline-none px-2 py-1 w-full mb-6'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className='flex justify-center'>
                      <button type="submit" className='bg-green-primary rounded-full py-2 w-60 cursor-pointer flex justify-center items-center hover:opacity-80 text-15 text-white mt-7'>
                        {status === 'sending' ? 'Sending...' : 'Notify me'}
                      </button>
                    </div>
                  </div>
                  {status === 'error' && (
                    <div dangerouslySetInnerHTML={{ __html: message }} className='text-red-500 text-center mt-7' />
                  )}
                  {status === 'success' && (
                    <div dangerouslySetInnerHTML={{ __html: message }} className='text-green-600 text-center mt-7' />
                  )}
                </form>
              </>
            )}
          />
        </div>
      </div>
      <div className={'overlay'} onClick={() => setOpenPopup(false)} />
    </div>
  )
}

export default MailchimpPopup;