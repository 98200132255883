import Web3 from 'web3';
import { RPC, DEFAULT_NET } from '../abis/constants';
import * as Metamask from './wallet-metamask';
import * as WalletConnect from './wallet-connect';

export const getDefaultWallet = () => {
  return {
    walletProviderName: "",
    address: "",
    wcConnector: null,
    wcProvider: null,
    web3: new Web3(RPC[DEFAULT_NET]['url']),
    connected: false,
    networkId: DEFAULT_NET,
  }
};

export const getWallet = async () => {
  const walletType = localStorage.getItem('wallet_type');
    
  if (walletType === 'metamask') {
    return await Metamask.connect();
  } else if (walletType === 'wallet-connect') {
    return await WalletConnect.connect();
  } else {
    return {
      walletProviderName: "",
      address: "",
      wcConnector: null,
      wcProvider: null,
      web3: new Web3(RPC[DEFAULT_NET]['url']),
      connected: false,
      networkId: DEFAULT_NET,
      chainId: null,
    }
  }
}

export const disconnectWallet = async () => {
  localStorage.removeItem('wallet_type');
}